import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { camelizeKeys } from 'humps';

import { UserInfo as UserInfoInterface, JWTUserInfo } from 'types';
import { fetchUserInfo } from 'services/api';
import Cookie, { keys } from 'services/cookie';
import { authenticate } from 'services/firebase';

const decodedJwt = () => {
  try {
    return camelizeKeys(jwtDecode(Cookie.get(keys.JWT))) as JWTUserInfo;
  } catch {
    return undefined;
  }
};

export default function useUserInfo() {
  const [loading, setLoading] = useState(true);
  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfoInterface>();

  const handleSetCookie = (userInfoPayload: UserInfoInterface) =>
    Cookie.set(keys.USER_INFO, userInfoPayload, { expiresIn: 3600 /* 1 hour */ });

  const handleAuthenticate = async () => {
    await authenticate(userInfo?.firebaseCustomToken || '');
    setFirebaseLoading(false);
  };

  useEffect(() => {
    const savedUserInfo: UserInfoInterface | undefined = Cookie.get(keys.USER_INFO);

    const { operatorId: jwtOperatorId } = decodedJwt() || {};
    const { operatorId: userInfoOperatorId } = savedUserInfo || {};

    if (savedUserInfo && jwtOperatorId === userInfoOperatorId) {
      const [currentSubdomain] = window.location.hostname.split('.');
      const { portal: { subdomain: savedSubdomain } = {} } = savedUserInfo;

      if (currentSubdomain === savedSubdomain) {
        setUserInfo(savedUserInfo);
        return;
      }
    }

    fetchUserInfo().then(({ status, data }) => {
      if (status) {
        setUserInfo(data);
        handleSetCookie(data);
      }
    });
  }, []);

  useEffect(() => {
    if (userInfo !== undefined) {
      setLoading(false);
      handleAuthenticate();
    }
  }, [userInfo]);

  return {
    loading,
    firebaseLoading,
    ...userInfo,
  };
}
